import React, { useState, useEffect, useContext } from 'react'
import '../style/Gallery.css'
import GalleryItem from '../components/GalleryItem.jsx'
import AuthContext from '../context/AuthContext.jsx'
import useAxios from '../utils/useAxios.js'

function Gallery() {
  let { user } = useContext(AuthContext)
  let api = useAxios()
  let [isLoading, setIsLoading] = useState(false)

  let [works, setWorks] = useState([])

  useEffect(() => {
    document.title = 'Галерея'
    getWorks()
  }, [])

  function compareById(a, b) {
    let al = a.id
    let bl = b.id
    if (al > bl) return 1
    if (al === bl) return 0
    if (al < bl) return -1
  }

  let getWork = async id => {
    if (works.map(work => work.id).includes(id)) return
    api.get(`/api/works/${id}/`, { anon: !user }).then(response => {
      let data = response.data
      setWorks(works => [...works, data].sort(compareById))
    })
  }

  let getWorks = async () => {
    setIsLoading(true)
    let url = `/api/`
    let anon = true
    if (['admin', 'teacher'].includes(user?.status)) {
      anon = false
      url += `works/`
    } else url += 'public_works/'
    try {
      let response = await api.get(url, { anon: anon })
      let data = response?.data
      data?.works.forEach(element => {
        getWork(element)
      })
    } catch {
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div id="back">
      <label id="header_gallery">{isLoading ? 'ЗАГРУЗКА...' : 'ГАЛЕРЕЯ'}</label>
      <div id="works">
        {works
          .map(work => (
            <GalleryItem
              key={work.id}
              workPar={work}
              deleteMe={() => setWorks(works.filter(w => w.id !== work.id))}
            />
          ))
          .reverse()}
      </div>
    </div>
  )
}

export default Gallery
