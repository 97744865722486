import gameToken from '../../../style/imgs/gametoken.webp'
import minus from './iconMinus.svg'
import plus from './iconPlus.svg'
import './style.css'
import studentStore from '../../../store/studentStore'
import { observer } from 'mobx-react-lite'
const ShopCard = ({ card }) => {
  const handleIncrement = () => {
    if (card.count === 10) return
    studentStore.addShopItem(card)
  }
  const handleDecrement = () => {
    if (card.count === 0) return
    studentStore.popFromShop(card)
  }
  return (
    <article className={card.count > 0 ? 'card-shop --choosen' : 'card-shop'}>
      <div className="card-shop__image">
        <img src={card.image} alt="Изображение товара" />
      </div>

      <div className="card-shop__title">
        <h3>{card.title}</h3>
        <p>арт.{card.article}</p>
      </div>
      <div className="card-shop-price">
        <img className="card-shop__gametoken" src={gameToken} alt="" />
        <p>{card.price}</p>
      </div>

      <footer>
        <button onClick={handleDecrement} className="card-shop__button">
          <img src={minus} alt="" />
        </button>
        <div
          className={
            card.count > 0
              ? 'card-shop__card.count --choosen-card.count'
              : 'card-shop__card.count'
          }>
          {card.count}
        </div>
        <button onClick={handleIncrement} className="card-shop__button">
          <img src={plus} alt="" />
        </button>
      </footer>
    </article>
  )
}
export default observer(ShopCard)
