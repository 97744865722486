import './buttons.css'

const ButtonDeafult = ({
  children,
  styles,
  handleClick,
  isDisabled = false,
}) => {
  return (
    <button
      disabled={isDisabled}
      style={styles}
      onClick={handleClick}
      className="button_default">
      {children}
    </button>
  )
}

export { ButtonDeafult }
