import { Fragment, useEffect, useState } from 'react'
import { ButtonDeafult } from '../components/UI/Buttons/Buttons'
import ShopCard from '../components/Widjets/ShopCard/ShopCard'
import studentStore from '../store/studentStore'
import { observer } from 'mobx-react-lite'
import CartItem from '../components/Widjets/CartItem/CartItem'
import useAxios from '../utils/useAxios'
import { Details } from '../components/UI/Details/details'
import gameToken from '../style/imgs/gametoken.webp'
function ShopPage() {
  const api = useAxios()

  const [isAlert, setIsAlert] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const handleClose = () => {
    setIsAlert(false)
  }

  const handleBuy = async () => {
    console.log(
      studentStore?.student?.user?.profile?.game_tokens,
      studentStore?.sumGameTokens,
    )
    if (!studentStore?.student?.user?.profile?.game_tokens) {
      alert(
        'Что-то не так с геймтокенами, попробуй зайти в личный кабинет, а потом обратно в магазин',
      )
      return
    }
    if (
      studentStore?.student?.user?.profile?.game_tokens <
      studentStore?.sumGameTokens
    ) {
      setIsAlert(true)
      setTimeout(() => {
        setIsAlert(false)
      }, 7000)
      return
    }
    try {
      await api
        .put(`/api/users/${studentStore.student.user.id}/buy_merch/`, {
          price: studentStore.sumGameTokens,
        })
        .then(() => {
          studentStore.fetchOrder()
          return
        })
        .then(() => {
          setIsSuccess(true)
          setTimeout(() => {
            setIsSuccess(false)
          }, 7000)
          studentStore.clearShopAndCart()
        })
        .catch(err => {
          console.log(err.message)
        })
    } catch (error) {
      console.log('Ошибка', error)
    }
  }

  useEffect(() => {
    if (studentStore.shopList.length === 0) {
      studentStore.loadCards()
    }
  }, [])

  return (
    <main className="shop-page">
      <h1>Магазин призов!</h1>
      <div className="shop-list">
        {studentStore.shopList.length > 0 &&
          studentStore.shopList.map((item, i) => {
            return (
              <Fragment key={i}>
                <ShopCard card={item} />
              </Fragment>
            )
          })}
      </div>
      <div className="shop-page__cart-wrapper">
        <h2>Корзина:</h2>
        {studentStore.cartList.length > 0 ? (
          <div className="cart-list">
            {studentStore.cartList.map((item, i) => {
              return (
                <Fragment key={i}>
                  <CartItem card={item} />
                </Fragment>
              )
            })}
            <ButtonDeafult
              handleClick={() => {
                handleBuy()
              }}>
              Оформить заказ
            </ButtonDeafult>
          </div>
        ) : (
          <p>Ваша корзина пуста :(</p>
        )}
      </div>
      {isAlert && (
        <div className="shop-alert">
          <h2>Нужно больше геймтокенов :(</h2>
          <ButtonDeafult handleClick={handleClose}>Ok</ButtonDeafult>
        </div>
      )}
      {isSuccess && (
        <div className="shop-alert">
          <h2>Твой заказ успешно отправлен!</h2>
          <ButtonDeafult
            handleClick={() => {
              setIsSuccess(false)
            }}>
            Ok
          </ButtonDeafult>
        </div>
      )}

      <Details title={'За что можно получить токены?'}>
        <ul>
          <li>🔸 Посещение занятия +10 токенов</li>
          <li>
            🔸 Быстрее всех справился с заданием на уроке, активнее всех работал
            на занятии +3 токена
          </li>
          <li>🔸 Помог другу разобраться с заданием +5 токенов</li>
          <li>🔸 За победу в конкурсе или олимпиаде +25 токенов</li>
          <li>
            🔸 Своевременно и правильно выполнил домашнее задание +15 токенов
            (за один раз не более 15, даже если сделали несколько заданий). Если
            задание выполнено частично верно +10 токенов
          </li>
          <li>
            🔸 Написал отзыв в социальных сетях, сделал репост в Instagram об
            обучении в IT-школе +15 токенов
          </li>
          <li>🔸 Привёл друга +20 токенов</li>
        </ul>
      </Details>
      <Details title={'За какие нарушения ученик может не получить токены?'}>
        <ul>
          <li>
            🔹 Первое - устное предупреждение, после второго предупреждения -
            вычет 5 токенов
          </li>
          <li>
            🔹 Пропуск занятия 10 токенов (Если ученик пропустил занятие, токены
            за пропущенный урок не начисляются)
          </li>
          <li>🔹 Сидение в телефоне на уроке -5 токенов</li>
          <li>🔹 Игры/ютуб на уроке -5 токенов</li>
          <li>
            🔹 Публикация в галерею электронных дневников не своей работы -15
            токенов
          </li>
          <li>
            🔹 Публикация в галерею электронных дневников нецензурного поста или
            поста не относящегося к тематике занятий школы -50 токенов.
          </li>
        </ul>
      </Details>
      <Details title={'Когда можно обменять токены на призы?'}>
        Обменять гейм токены на призы можно 2 раза в год:
        <ol>
          <li>
            {' '}
            на Новый год Можно потратить не более 300 гейм токенов Призы
            заказываются перед Новым годом, а вручаются после новогодних каникул
          </li>
          <li>
            {' '}
            на конец учебного года Можно потратить не более 500 гейм токенов
            Оставшиеся токены остаются на следующее полугодие
          </li>
        </ol>
      </Details>

      <div className="shop__gametokens">
        <img src={gameToken} alt="" />
        <p>
          {studentStore?.student?.user?.profile?.game_tokens
            ? studentStore?.student?.user?.profile?.game_tokens
            : 0}
        </p>
      </div>
    </main>
  )
}

export default observer(ShopPage)
