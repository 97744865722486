import './style.css'

import './style.css'
import studentStore from '../../../store/studentStore'
import deleteIcon from './delete.svg'
import { observer } from 'mobx-react-lite'
const CartItem = ({ card }) => {
  const handleDecrement = () => {
    studentStore.popFromShop(card)
  }
  return (
    <article className="cart-item">
      <h3 className="cart-item__title">
        {card.title} <span>арт.{card.article}</span>{' '}
      </h3>

      <p className="cart-item__count">{card.count} шт</p>
      <p className="cart-item__total">{card.count * card.price}</p>
      <button onClick={handleDecrement} className="cart-item__button">
        <img src={deleteIcon} alt="" />
      </button>
    </article>
  )
}
export default observer(CartItem)
