
import { makeAutoObservable, runInAction, toJS } from "mobx";
import axios from "axios";
import { $api } from "../components/http/http";



class StudentStore {
    sumGameTokens = 0;
    student = null
    shopList = [];
    cartList = [];

    constructor() {
        makeAutoObservable(this);
    }

    async loadCards() {
        try {
            const res = await axios.get('/shopCards/cards.json');
            runInAction(() => {
                this.shopList = res.data;
            });
        } catch (err) {
            console.error(err);
        }
    }
    async setUser(user) {
        this.student = { ...this.student, user };
        console.log(toJS(this.student));

    }

    async fetchOrder() {
        const URL = "https://script.google.com/macros/s/AKfycbzJ7OosWIZ7SXDI72sdjy2QwAX3UMAj-n8YlnPGSOU2S3iPxfeBiAK3GsblXCAJPpu-9A/exec"
        const titles = this.cartList.map(item => item.title).join(", ");
        const articles = this.cartList.map(item => item.article).join(", ");
        const groups = this.student.user.study_groups.map(item => item.name).join(", ");
        const DATA = {
            p1: this.student.user.first_name + " " + this.student.user.last_name,
            p2: groups,
            p3: articles,
            p4: titles,
            p5: this.sumGameTokens,
            // p6: new Date().toISOString().split("T")[0], // текущая дата
        }
        try {
            const res = await fetch(URL, {
                redirect: "follow",
                method: "POST",
                body: JSON.stringify(DATA),
                headers: {
                    "Content-Type": "text/plain;charset=utf-8",
                },
            },);
            runInAction(() => {
                console.log("Response:", res.data);
                this.student.user.profile.game_tokens -= this.sumGameTokens
            });
        } catch (err) {
            console.error("Error:", err);
        }
    }
    async buyMerch() {
        await $api.put(`/api/users/${this.student.user.id}/buy_merch/`, {
            price: this.sumGameTokens
        })
            .then(res => {
                console.log(res.data);
            })
            .then(() => {
                this.fetchOrder()
            })
            .catch(err => {
                console.log(err.config, err.message);

            })

    }
    addShopItem(card) {
        let cardIndex = this.cartList.findIndex((item) => item.article === card.article);
        let cardIndexFromShop = this.shopList.findIndex((item) => item.article === card.article);
        this.shopList[cardIndexFromShop].count++;
        if (cardIndex !== -1) {
            this.cartList[cardIndex].count += 1;
        } else {
            this.cartList.push({ ...card, count: card.count });
        }
        this.sumGameTokens += card.price;


    }
    clearShopAndCart() {
        this.shopList.forEach(card => card.count = 0);
        this.cartList = [];
        this.sumGameTokens = 0;
    }
    popFromShop(card) {
        let cardIndex = this.cartList.findIndex((item) => item.article === card.article);
        let cardIndexFromShop = this.shopList.findIndex((item) => item.article === card.article);
        this.shopList[cardIndexFromShop].count--;
        if (cardIndex !== -1) {
            this.cartList[cardIndex].count -= 1;
            if (this.cartList[cardIndex].count === 0) {
                this.cartList.splice(cardIndex, 1);
            }
        }
        this.sumGameTokens -= card.price;
    }
}

export default new StudentStore();
