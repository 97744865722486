import React, { useState, useContext } from 'react'
import ArrowUp from '../style/imgs/arrow_up.svg'
import ArrowDown from '../style/imgs/arrow_down.svg'
import Attach from '../style/imgs/attach_work.svg'
import Download from '../style/imgs/download_hw.svg'
import '../style/HomeWork.css'
import AuthContext from '../context/AuthContext'
import useAxios from '../utils/useAxios'

function HomeWork({ task, isCompleted, complete }) {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const [data, setData] = useState(
    task.homeworks.length === 0 ? null : task.homeworks[0],
  )

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }

  const { user } = useContext(AuthContext)
  let api = useAxios()
  let [isLoading, setIsLoading] = useState(false)
  let [isDownloading, setIsDownloading] = useState(false)

  async function handleDownload() {
    setIsDownloading(true)
    let blob = await fetch(task.file_url).then(r => r.blob())
    let url = window.URL.createObjectURL(blob)
    let a = document.createElement('a')
    a.href = url
    a.download = `${task.file_url.split('/').pop()}`
    setIsDownloading(false)
    a.click()
  }

  async function handleHwDownload() {
    setIsDownloading(true)
    let blob = await fetch(data.file_url).then(r => r.blob())
    let url = window.URL.createObjectURL(blob)
    let a = document.createElement('a')
    a.href = url
    a.download = `${data.file_url.split('/').pop()}`
    setIsDownloading(false)
    a.click()
  }

  const [file, setFile] = useState(null)

  let createHomework = async e => {
    e.preventDefault()
    if (isLoading) return
    if (isCompleted) return
    if (!file) {
      alert('Вы не выбрали файл')
      return
    }
    setIsLoading(true)
    const formData = new FormData()
    formData.append(`user`, user.user_id)
    formData.append(`task`, task.id)
    formData.append(`file_url`, file, file.name)
    try {
      let response = await api.post(`/api/homeworks/create/`, formData)
      setIsLoading(false)
      complete(response.data)
    } catch (error) {
      setIsLoading(false)
    }
  }

  let updateHomework = async e => {
    e.preventDefault()
    if (isLoading) return
    if (!file) {
      alert('Вы не выбрали файл')
      return
    }
    setIsLoading(true)
    const formData = new FormData()
    formData.append(`id`, data.id)
    formData.append(`file_url`, file, file.name)
    try {
      let response = await api.patch(`/api/homeworks/${data.id}/`, formData)
      setIsLoading(false)
      setData(response.data)
      complete(response.data)
    } catch (error) {
      setIsLoading(false)
    }
  }

  function handleFileChange(event) {
    setFile(event.target.files[0])
  }

  return (
    <div id="background_hw">
      <div className="wrap_hw">
        <div className="task_short">
          <label className="label_homework" id="date">
            {task?.date}
          </label>
          <label className="label_homework" id="theme">
            {task?.topic}
          </label>
          <label className="label_homework" id="program">
            {task?.environment}
          </label>

          <button
            style={{ marginLeft: 'auto', marginRight: '15px' }}
            className="btn_homework"
            onClick={toggleCollapse}>
            {isCollapsed ? (
              <img src={ArrowDown} style={{ width: '35px' }} alt="Свернуть" />
            ) : (
              <img src={ArrowUp} style={{ width: '35px' }} alt="Развернуть" />
            )}
          </button>
        </div>
        {!isCollapsed && (
          <div>
            <pre id="homework_text">{task?.text}</pre>
            {!!data?.file_url && (
              <div style={{ padding: '20px 0 0 20px' }}>
                <div className="hw_buttons">
                  <img
                    className="btn_homework"
                    src={Download}
                    alt="Скачать"></img>
                  <button
                    className="homework_bottom"
                    onClick={handleHwDownload}>
                    {isDownloading
                      ? 'Загрузка...'
                      : 'Скачать отправленный файл'}
                  </button>
                </div>
              </div>
            )}
            <div className="task_long">
              {!!task?.file_url && (
                <div className="hw_buttons">
                  <img
                    className="btn_homework"
                    src={Download}
                    alt="Скачать"></img>
                  <button className="homework_bottom" onClick={handleDownload}>
                    {isDownloading ? 'Загрузка...' : 'Скачать'}
                  </button>
                </div>
              )}

              {!isCompleted ? (
                <div>
                  <div className="hw_buttons">
                    <img
                      className="btn_homework"
                      src={Attach}
                      alt="Прикрепить"></img>
                    <label
                      className="homework_bottom"
                      style={{ marginLeft: '15px' }}>
                      Прикрепить файл
                      <input type="file" onChange={handleFileChange} hidden />
                    </label>
                    <label>
                      {!file ? 'Не выбрано' : 'Выбран файл:' + file?.name}
                    </label>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="hw_buttons">
                    <img
                      className="btn_homework"
                      src={Attach}
                      alt="Прикрепить"></img>
                    <label
                      className="homework_bottom"
                      style={{ marginLeft: '15px' }}>
                      Прикрепить другой файл
                      <input type="file" onChange={handleFileChange} hidden />
                    </label>
                    <label id="work_name">
                      {!file ? 'Не выбрано' : 'Выбран файл:' + file?.name}
                    </label>
                  </div>
                </div>
              )}
              <button
                id="send_hw_button"
                onClick={e =>
                  isCompleted ? updateHomework(e) : createHomework(e)
                }>
                {isLoading ? 'Загрузка...' : 'Отправить Д/З'}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default HomeWork
