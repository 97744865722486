import React, { useContext, useState, useEffect } from 'react'
import ProgressBar from '@ramonak/react-progress-bar'
import Modal from 'react-modal'
import '../style/Account.css'
import '../style/MyGallery.css'
import CharPic from '../style/imgs/CharPic.png'
import ExitButton from '../style/imgs/exit_button.svg'
import LeftWave from '../style/imgs/left_wave_crop_ready.svg'
import RightWave from '../style/imgs/right_wave_crop_ready.svg'
import MyGallery from './MyGallery.jsx'
import HomeWork from '../components/HomeWork.jsx'
import PickAvatar from '../components/PickAvatar.jsx'
import AuthContext from '../context/AuthContext.jsx'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import '../style/ProgressBar.css'
import useAxios from '../utils/useAxios.js'
import studentStore from '../store/studentStore.js'

export default function Account() {
  const { user } = useContext(AuthContext)

  const [userData, setUserData] = useState(null)
  const [works, setWorks] = useState([])
  const [tasks, setTasks] = useState([])
  const [completedTasks, setCompletedTasks] = useState([])
  const [avatars, setAvatars] = useState([])
  const [categories, setCategories] = useState([])
  const [fields, setFields] = useState([])

  let api = useAxios()
  let [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    document.title = 'Личный кабинет'
    getData()
  }, [])

  function compareById(a, b) {
    let al = a.id
    let bl = b.id
    if (al > bl) return 1
    if (al === bl) return 0
    if (al < bl) return -1
  }

  function compareByDate(a, b) {
    let al = a.date
    let bl = b.date
    if (al > bl) return 1
    if (al === bl) return 0
    if (al < bl) return -1
  }

  function compareByPrice(a, b) {
    let al = a.price
    let bl = b.price
    if (al > bl) return 1
    if (al === bl) return 0
    if (al < bl) return -1
  }

  const getWork = async id => {
    if (works.map(work => work.id).includes(id)) return
    let response = await api.get(`/api/works/${id}/`)
    let data = response?.data
    setWorks(works => [...works, data].sort(compareById))
  }

  const getTask = async id => {
    if ([...tasks, ...completedTasks].map(task => task.id).includes(id)) return
    let response = await api.get(`/api/tasks/${id}/`)
    let data = {
      ...response.data,
      homeworks: response.data.homeworks.filter(
        hw => hw.user?.id === user?.user_id,
      ),
    }
    if (data.homeworks.map(item => item.user?.id).includes(user?.user_id))
      setCompletedTasks(completedTasks =>
        [...completedTasks, data].sort(compareByDate),
      )
    else setTasks(tasks => [...tasks, data].sort(compareByDate))
  }

  const getData = async () => {
    setIsLoading(true)
    try {
      let response = await api.get(`/api/users/${user.user_id}/account/`)
      let data = response?.data
      try {
        getFields()
        getUser()
        data?.tasks.forEach(element => {
          getTask(element)
        })
        data?.works.forEach(element => {
          getWork(element)
        })
        getAvatars()
        getCategories()
      } catch {
      } finally {
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
    }
  }

  const getUser = async () => {
    let response = await api.get(`/api/users/${user.user_id}/`)
    setUserData(response.data)
    setAvatar(response.data.profile.avatar)
    studentStore.setUser(response.data)
  }

  const getAvatars = async () => {
    let response = await api.get(`/api/avatars/`)
    let data = [...response.data]
    data.sort(compareByPrice)
    setAvatars(data)
  }

  const getCategories = async () => {
    let response = await api.get(`/api/avatar_categories/`)
    setCategories(response.data.map(item => item.name).sort())
  }

  const getFields = async () => {
    let response = await api.get(`/api/fields/`)
    let data = response?.data
    setFields(data)
  }

  const createWork = async data => {
    if (isLoading) return false
    setIsLoading(true)
    data.append('user', user.user_id)
    try {
      let response = await api.post(`/api/works/create/`, data)
      setWorks([...works, response.data])
      setIsLoading(false)
      return true
    } catch (error) {
      setIsLoading(false)
      return false
    }
  }

  //функции модального окна
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const openModal = () => {
    setModalIsOpen(true)
  }

  const closeModal = () => {
    setModalIsOpen(false)
  }

  const [category, setCategory] = useState('')

  function handleCategoryChange(value) {
    setCategory(value)
  }

  const [avatar, setAvatar] = useState(null)

  const handleAvatar = (item, buy) => {
    if (isLoading) return false
    if (buy) {
      if (
        window.confirm(
          `Вы уверены что хотите купить аватарку? Ее стоимость - ${item.price} геймтокенов`,
        )
      ) {
        buyAvatar(item)
        closeModal()
        return true
      }
      return false
    }
    changeAvatar(item)
    closeModal()
    return true
  }

  useEffect(() => {
    document.body.style.overflow = modalIsOpen ? 'hidden' : 'unset'
  }, [modalIsOpen])

  const buyAvatar = async item => {
    if (isLoading) return
    setIsLoading(true)
    try {
      await api.patch(`/api/users/${user.user_id}/buy_avatar/`, {
        avatar: item.id,
      })
      setAvatar(item)
      setUserData({
        ...userData,
        unlocked_avatars: [...userData.unlocked_avatars, item],
        profile: {
          ...userData.profile,
          game_tokens: userData.profile.game_tokens - item.price,
          avatar: item,
        },
      })
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  let changeAvatar = async item => {
    if (isLoading) return
    setIsLoading(true)
    try {
      await api.patch(`/api/users/${user.user_id}/change_avatar/`, {
        avatar: item.id,
      })
      setAvatar(item)
      setUserData({
        ...userData,
        profile: {
          ...userData.profile,
          avatar: item,
        },
      })
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  let completeTask = (id, isCreate, data) => {
    alert(`Домашка успешно ${isCreate ? 'отправлена' : 'изменена'}`)
    let task = tasks.find(t => t.id === id)
    if (isCreate) {
      setTasks(tasks.filter(t => t.id !== id))
      setCompletedTasks([...completedTasks, { ...task, homeworks: [data] }])
    } else
      setCompletedTasks(
        completedTasks.map(item =>
          item.id === id ? { ...item, homeworks: [data] } : item,
        ),
      )
  }

  //содержание модального окна с выбором аватарки
  const ModalContent = (
    <>
      <button
        id="close_avatar_pick"
        className="hide_button"
        onClick={closeModal}>
        <img src={ExitButton} style={{ width: '25px' }} alt="Закрыть"></img>
      </button>

      <div id="pick_avatar_modal">
        <div id="avatar_cat">
          <button
            className={category === '' ? 'category_picked' : 'category'}
            onClick={() => handleCategoryChange('')}>
            Все аватарки
          </button>
          {categories.map(item => (
            <button
              className={category === item ? 'category_picked' : 'category'}
              onClick={() => handleCategoryChange(item)}>
              {item}
            </button>
          ))}
        </div>

        <div id="pick_avatar">
          {avatars
            .filter(a => category === '' || a.category?.name === category)
            .map((item, index) => (
              <PickAvatar
                key={item.id}
                avatar={item}
                isHaving={userData?.unlocked_avatars
                  .map(i => i.id)
                  .includes(item.id)}
                isIt={item.id === avatar?.id}
                handle={buy => handleAvatar(item, buy)}
              />
            ))}
        </div>
      </div>
    </>
  )

  const [description, setDescription] = useState([])
  const [date, setDate] = useState(new Date().toLocaleDateString('en-CA'))

  function handleDateChange(event) {
    setDate(event.target.value)
  }

  useEffect(() => {
    if ((fields.length > 0) & !!userData) {
      if (!!date) {
        let newDescription = []
        fields.forEach(item => {
          newDescription.push({
            name: item.name,
            game_tokens: 0,
          })
        })
        newDescription.push({
          name: 'Дополнительно',
          game_tokens: 0,
        })
        let incomes = userData?.incomes.filter(income => date === income.date)
        incomes.forEach(income => {
          income.fields.forEach(item => {
            let ind = fields.findIndex(f => f.id === item)
            if (ind !== -1) {
              newDescription[ind].game_tokens += fields[ind].game_tokens
            }
          })
          newDescription[newDescription.length - 1].game_tokens +=
            income.additional_game_tokens
        })
        setDescription(newDescription)
      } else setDescription([])
    }
  }, [date, fields, userData])

  //функции переключения между дз и тасками
  const [showHomeWork, setShowHomeWork] = useState(true)

  const toggleComponent = () => {
    setShowHomeWork(true)
  }

  const toggleComponent1 = () => {
    setShowHomeWork(false)
  }

  const popover = (
    <Popover id="popover">
      <Popover.Header as="h3">Маленький советик</Popover.Header>
      <Popover.Body>
        Уровень - показатель твоих посещений. Посещай занятия и уровень будет
        расти.
      </Popover.Body>
    </Popover>
  )

  return (
    <>
      <div id="top">
        <img src={LeftWave} id="left_wave" alt="Волна"></img>
        <img src={RightWave} id="right_wave" alt="Волна"></img>
        {/* <div id="acc_wrapper"> */}
        <div id="info">
          <div className="acc_gradient">
            <pre id="pre_style">
              <label id="name">{userData?.first_name}</label>
              <label id="surname">{userData?.last_name}</label>
              <label id="birthday">{userData?.profile.birth_date}</label>
            </pre>
          </div>
          <div className="acc_gradient">
            <label id="my_groups">Мои группы:</label>
            <ul id="group">
              {userData?.study_groups
                .map(group => <li key={group.id}>{group.name}</li>)
                .reverse()}
            </ul>
          </div>
        </div>

        <div id="profile">
          <button id="btn_picture" onClick={openModal}>
            <img
              id="picture"
              src={avatar === null ? CharPic : avatar.image}
              alt="Аватарка"></img>
          </button>
        </div>

        <div id="stats">
          <div id="hint">
            <OverlayTrigger trigger="hover" placement="top" overlay={popover}>
              <label id="number">{userData?.profile.level} УРОВЕНЬ</label>
            </OverlayTrigger>
            <ProgressBar
              completed={!!userData ? userData?.profile.level : 0}
              maxCompleted={100}
              labelColor="#032552"
              bgColor="#cef96b"
              baseBgColor="white"
              height="8%"
              className="wrapper"
              customLabel={`${userData?.profile.level}`}
            />
          </div>
          <div id="about_token">
            {' '}
            <label id="token">
              {userData?.profile.game_tokens} ГЕЙМТОКЕНОВ
            </label>
            <input
              type="date"
              id="calendar"
              onChange={handleDateChange}
              value={date}></input>
            {description
              .filter(item => item.game_tokens > 0)
              .map(item => (
                <div id="info_token">
                  {item.name}: {item.game_tokens}
                </div>
              ))}
          </div>

          {/* <div id="level"></div> */}
        </div>
        {/* </div> */}
      </div>

      <div id="bottom">
        {/** кнопки переключения компонент */}
        <div id="account_buttons">
          <button
            className={
              showHomeWork
                ? 'account_head_button_active'
                : 'account_head_button'
            }
            onClick={toggleComponent}>
            МОЯ ГАЛЕРЕЯ
          </button>
          <button
            className={
              showHomeWork
                ? 'account_head_button'
                : 'account_head_button_active'
            }
            onClick={toggleComponent1}>
            МОИ ЗАДАНИЯ
          </button>
        </div>

        {/** переключения компонент */}
        {showHomeWork ? (
          <MyGallery
            works={works}
            setWorks={setWorks}
            create={item => createWork(item)}
          />
        ) : (
          <div id="background_pattern">
            <div className="hw_words">Новые задания:</div>
            <div>
              {tasks
                .map(task => (
                  <HomeWork
                    key={task.id}
                    task={task}
                    isCompleted={false}
                    complete={data => completeTask(task.id, true, data)}
                  />
                ))
                .reverse()}
            </div>
            <div className="hw_words"> Выполнено: </div>
            <div>
              {completedTasks
                .map(task => (
                  <HomeWork
                    key={task.id}
                    task={task}
                    isCompleted={true}
                    complete={data => completeTask(task.id, false, data)}
                  />
                ))
                .reverse()}
            </div>
          </div>
        )}

        {/** тут модальное окно открывается */}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={{
            overlay: {
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              zIndex: '100',
            },
            content: {
              background: 'rgba(195, 228, 255, 1)',
              borderRadius: '20px',
              zIndex: '150',
            },
          }}>
          {ModalContent}
        </Modal>
      </div>
    </>
  )
}
