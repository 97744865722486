import axios from "axios";
const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
};
const $api = axios.create({
    withCredentials: false,
    baseURL: process.env.REACT_APP_API_ENDPOINT || "http://localhost:5000",
    headers: headers,
});

$api.interceptors.request.use((config) => {
    const tokens = JSON.parse(localStorage.getItem('authTokens'))
    if (localStorage.getItem("token")) {
        config.headers.Authorization = `Bearer ${tokens?.access}`;
    }
    config.timeout = 1000;
    return config;
});

export { $api };