import React from 'react'
import './App.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { AuthProvider } from './context/AuthContext.jsx'
import Login from './pages/Login.jsx'
import PrivateWrapper from './utils/PrivateWrapper.js'
import Admin from './pages/Admin.jsx'
import Account from './pages/Account.jsx'
import Gallery from './pages/Gallery.jsx'
import Teacher from './pages/TeacherAcc.jsx'
import NavBar from './components/Navbar/index.jsx'
import 'bootstrap/dist/css/bootstrap.min.css'
import ShopPage from './pages/Shop.jsx'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <AuthProvider>
          <NavBar />
          <Routes>
            <Route path="/gallery" element={<Gallery />} />
            <Route element={<PrivateWrapper role={'user'} />}>
              <Route path="/" element={<Login />} />
            </Route>
            <Route element={<PrivateWrapper role={'admin'} />}>
              <Route path="/admin" element={<Admin />} />
            </Route>
            <Route element={<PrivateWrapper role={'teacher'} />}>
              <Route path="/teacher" element={<Teacher />} />
            </Route>
            <Route element={<PrivateWrapper role={'student'} />}>
              <Route path="/account" element={<Account />}></Route>
              <Route path="/shop" element={<ShopPage />} />
            </Route>
            <Route path="*" element={<Gallery />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </div>
  )
}

export default App
