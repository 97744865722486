import { useContext } from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import AuthContext from '../context/AuthContext'

const roles = [
  "admin", "teacher", "account"
]

const PrivateWrapper = ({ role }) => {
  let { user } = useContext(AuthContext)
  let isAllowed = !!user && (!role || user?.status === role)
  if (role === 'user' && !user) isAllowed = true
  // if (user?.status === 'admin') rolePath = '/admin'
  // else if (user?.status === 'teacher') rolePath = '/teacher'
  // else if (user?.status === 'student') rolePath = '/account'
  // else rolePath = '/gallery'
  let rolePath = "/" + roles.find(r => r.role === user?.status)

  return isAllowed ? <Outlet /> : <Navigate to={rolePath} />
}

export default PrivateWrapper
