import './details.css'

const Details = ({ title, children }) => {
  return (
    <details className="details">
      <summary>{title}</summary>
      <p>{children}</p>
    </details>
  )
}
export { Details }
