import React, { useState, useEffect, useContext } from 'react'
import '../style/TeacherAcc.css'
import TeacherHW from '../components/TeacherHW.jsx'
import AuthContext from '../context/AuthContext.jsx'
import useAxios from '../utils/useAxios.js'
import Checkbox from '@mui/material/Checkbox'

function TeacherAcc() {
  let { user } = useContext(AuthContext)
  let api = useAxios()
  let [isLoading, setIsLoading] = useState(false)

  let [userData, setUserData] = useState()
  let [groups, setGroups] = useState([])
  let [tasks, setTasks] = useState([])
  let [homeworks, setHomeworks] = useState([])
  let [doneHomeworks, setDoneHomeworks] = useState([])
  let [fields, setFields] = useState([])
  let [incomes, setIncomes] = useState([])

  let clearTask = {
    topic: '',
    environment: '',
    text: '',
    file: null,
  }
  let [task, setTask] = useState({ ...clearTask })
  let [selectedGroup, setSelectedGroup] = useState(null)
  let [date, setDate] = useState(new Date().toLocaleDateString('en-CA'))

  useEffect(() => {
    document.title = 'Кабинет преподавателя'
    getData()
  }, [])

  function compareById(a, b) {
    let al = a.id
    let bl = b.id
    if (al > bl) return 1
    if (al === bl) return 0
    if (al < bl) return -1
  }

  function compareByDate(a, b) {
    let al = a.date
    let bl = b.date
    if (al > bl) return 1
    if (al === bl) return 0
    if (al < bl) return -1
  }

  function compareByName(a, b) {
    let al = a.name.toLowerCase()
    let bl = b.name.toLowerCase()
    if (al > bl) return 1
    if (al === bl) return 0
    if (al < bl) return -1
  }

  let getGroup = async id => {
    if (groups.map(group => group.id).includes(id)) return
    let response = await api.get(`/api/groups/${id}/`)
    let data = response?.data
    setGroups(groups => [...groups, data].sort(compareByName))
  }

  const getTask = async id => {
    if (tasks.map(task => task.id).includes(id)) return
    let response = await api.get(`/api/tasks/${id}/`)
    let data = response?.data
    setTasks(tasks => [...tasks, data].sort(compareByDate))
  }

  const getData = async () => {
    setIsLoading(true)
    try {
      let response = await api.get(`/api/users/${user.user_id}/teacher/`)
      let data = response?.data
      try {
        getUserData()
        getFields()
        data?.groups.forEach(element => {
          getGroup(element)
        })
        data?.tasks.forEach(element => {
          getTask(element)
        })
      } catch {
      } finally {
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
    }
  }

  let getUserData = async () => {
    let response = await api.get(`/api/users/${user.user_id}/`)
    setUserData(response.data)
  }

  let getFields = async () => {
    let response = await api.get(`/api/fields/`)
    setFields(response.data)
  }

  let createTask = async () => {
    if (isLoading) return
    if (isTaskEmpty(true)) {
      alert('Задание не может быть пустым')
      return
    }
    if (!date) {
      alert('Выберите дату')
      return
    }
    if (!selectedGroup) {
      alert('Выберите группу')
      return
    }
    setIsLoading(true)
    const formData = new FormData()
    formData.append(`user`, user.user_id)
    formData.append(`group`, selectedGroup.id)
    formData.append(`topic`, task.topic)
    formData.append(`environment`, task.environment)
    formData.append(`text`, task.text)
    if (!!date) formData.append(`date`, date)
    if (!!task.file) formData.append(`file_url`, task.file, task.file.name)
    try {
      let response = await api.post(`/api/tasks/create/`, formData)
      setTasks([...tasks, response?.data])
      setIsLoading(false)
      alert('Задание выдано успешно')
    } catch (error) {
      setIsLoading(false)
    }
  }

  let updateTask = async () => {
    if (isLoading) return
    setIsLoading(true)
    const formData = new FormData()
    formData.append(`topic`, task.topic)
    formData.append(`environment`, task.environment)
    formData.append(`text`, task.text)
    if (task.file_url === '') formData.append(`file_url`, '')
    if (!!task.file) formData.append(`file_url`, task.file, task.file.name)
    try {
      let response = await api.patch(`/api/tasks/${task.id}/`, formData)
      let data = response?.data
      let new_tasks = tasks.map(item => {
        if (item.id === data?.id) return data
        return item
      })
      setTasks(new_tasks)
      setIsLoading(false)
      alert('Задание изменено успешно')
    } catch (error) {
      setIsLoading(false)
    }
  }

  let deleteTask = async () => {
    if (isLoading) return
    setIsLoading(true)

    try {
      await api.delete(`/api/tasks/${task.id}/`)
      let new_tasks = tasks.filter(item => item.id !== task.id)
      setTasks(new_tasks)
      setIsLoading(false)
      alert('Задание удалено успешно')
    } catch (error) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (date && selectedGroup) {
      let t = tasks.find(t => t.date === date && t.group === selectedGroup?.id)
      if (t !== undefined) {
        setTask({ ...t })
        if (!!t.homeworks) {
          let hws = [...t?.homeworks]
          setHomeworks(hws.filter(hw => !hw.is_approved))
          setDoneHomeworks(hws.filter(hw => hw.is_approved))
        }
      } else setTask({ ...clearTask })
    }
  }, [JSON.stringify(tasks)])

  function isTaskEmpty(isNew) {
    if (isNew) {
      return (
        task.topic === '' &&
        task.environment === '' &&
        task.text === '' &&
        task.file === null
      )
    } else {
      return (
        task.topic === '' &&
        task.environment === '' &&
        task.text === '' &&
        !task.file &&
        (task.file_url === '' || task.file_url === null)
      )
    }
  }

  let sendTask = e => {
    e.preventDefault()
    if (!!task?.id) {
      if (isTaskEmpty(false)) deleteTask()
      else updateTask()
    } else createTask()
  }

  let deleteFile = e => {
    setTask({ ...task, file_url: '' })
  }

  let updateHomework = async data => {
    if (isLoading) return
    setIsLoading(true)
    try {
      let response = await api.patch(`/api/homeworks/${data.id}/`, {
        is_approved: !data.is_approved,
      })
      if (data.is_approved) {
        setDoneHomeworks(doneHomeworks.filter(item => item.id !== data.id))
        setHomeworks([...homeworks, response.data])
      } else {
        setHomeworks(homeworks.filter(item => item.id !== data.id))
        setDoneHomeworks([...doneHomeworks, response.data])
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  let createIncomes = async e => {
    if (isLoading) return
    if (!date) {
      alert('Выберите дату')
      return
    }
    if (
      incomes.find(
        item =>
          item.additional_game_tokens != 0 &&
          !Number(item.additional_game_tokens),
      )
    ) {
      alert('Колонка "Дополнительно" заполнена некорректно')
      return
    }
    setIsLoading(true)
    try {
      let response = await api.post(
        `/api/users/${user.user_id}/incomes/handle/`,
        incomes,
      )
      setGroups(response?.data.sort(compareByName))
      alert('Операция прошла успешно')
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  function handleIncomeChange(event, userId) {
    let value = Number(event.target.value)
    let new_incomes = incomes.map(income => {
      if (income.user === userId) {
        let new_income = { ...income }
        if (new_income.fields.includes(value)) {
          new_income.fields = [...new_income.fields.filter(id => id !== value)]
        } else new_income.fields.push(value)
        let visiting = fields.find(item => item.name === 'Посещение')
        new_income.level_up = new_income.fields.includes(visiting?.id)
        return new_income
      }
      return income
    })
    setIncomes(new_incomes)
  }

  function handleAdditionalChange(event, userId) {
    let new_incomes = incomes.map(item =>
      item.user === userId
        ? {
            ...item,
            additional_game_tokens:
              event.target.value === '' ? 0 : event.target.value,
          }
        : item,
    )
    setIncomes(new_incomes)
  }

  useEffect(() => {
    let t = tasks.find(t => t.date === date && t.group === selectedGroup?.id)
    if (t !== undefined) {
      setTask({ ...t })
      if (!!t.homeworks) {
        let hws = [...t?.homeworks]
        setHomeworks(hws.filter(hw => !hw.is_approved))
        setDoneHomeworks(hws.filter(hw => hw.is_approved))
      }
    } else setTask({ ...clearTask })

    let new_incomes = []
    selectedGroup?.users.forEach(user => {
      let income = user.incomes.find(
        item => date === item.date && item.group === selectedGroup.id,
      )
      if (!income) {
        income = {
          user: user.id,
          group: selectedGroup.id,
          date: date,
          level_up: false,
          fields: [],
          additional_game_tokens: 0,
        }
      }
      new_incomes.push(income)
    })
    setIncomes(old => new_incomes)
  }, [date, selectedGroup])

  useEffect(() => {
    if (!!selectedGroup)
      setSelectedGroup(groups.find(item => item.id === selectedGroup.id))
  }, [groups])

  function handleGroupChange(group) {
    setSelectedGroup({ ...group })
  }

  function handleTopicChange(event) {
    setTask({ ...task, topic: event.target.value })
  }

  function handleEnvironmentChange(event) {
    setTask({ ...task, environment: event.target.value })
  }

  function handleTextChange(event) {
    setTask({ ...task, text: event.target.value })
  }

  function handleFileChange(event) {
    setTask({ ...task, file: event.target.files[0] })
  }

  function handleDateChange(event) {
    setDate(event.target.value)
  }

  return (
    <div style={{ overflow: 'hidden' }}>
      <div id="top_teacher">
        <label className="teacher_name">{userData?.last_name}</label>
        <label className="teacher_name" style={{ marginLeft: '10px' }}>
          {userData?.first_name}
        </label>
        <div
          id="line"
          style={{
            height: '1px',
            width: '100%',
            backgroundColor: 'black',
            margin: '10px auto 0px auto',
          }}></div>
      </div>
      <div id="bot_teacher">
        <div id="left_teacher">
          <div id="pick_group">
            <label id="my_group">Мои группы:</label>
            {groups?.map(group => (
              <button
                key={group.id}
                className={
                  selectedGroup?.id === group.id
                    ? 'group_name_picked'
                    : 'group_name'
                }
                onClick={() => handleGroupChange(group)}>
                <div id="label_group">{group.name}</div>
              </button>
            ))}
          </div>
        </div>

        {/* <div id='ver_line' style={{width: '1px', height: '90%', backgroundColor: 'black', margin: '10px'}}></div> */}

        <div id="right_teacher">
          <div>
            {/* див ниже должен появляться при первой отправке дз */}
            <div id="popout_hw">
              <label id="hw_done">Домашнее задание</label>
            </div>
            {/* форма ниже должна быть до первой отправки дз а потом открываться при 
                    нажатии на кнопку Редактировать и в идеале там должны отображаться текст 
                    и файлы, загруженные в прошлое отправление дз*/}
            <form id="teacher_create_hw" onSubmit={sendTask}>
              <input
                className="about_hw"
                type="text"
                placeholder="Тема занятия"
                value={task.topic}
                onChange={handleTopicChange}></input>
              <input
                className="about_hw"
                type="text"
                placeholder="Среда занятия"
                value={task.environment}
                onChange={handleEnvironmentChange}></input>
              <textarea
                rows="10"
                className="about_hw"
                id="hw_text"
                placeholder="Текст домашнего задания"
                value={task.text}
                onChange={handleTextChange}></textarea>
              <div id="hw_button">
                {!!task?.file_url && (
                  <>
                    <label
                      className="picked_file"
                      style={{
                        margin: '10px',
                      }}>{`Текущий файл: ${task?.file_url
                      .split('/')
                      .pop()}`}</label>
                    <div>
                      {' '}
                      <button id="delete_hw_file" onClick={() => deleteFile()}>
                        Удалить файл
                      </button>
                    </div>
                  </>
                )}
                <div id="choose_file">
                  <label id="choose_hw_file">
                    {!!task?.file_url ? 'Перевыбрать' : 'Прикрепить файл'}
                    <input
                      type="file"
                      onClick={e => (e.target.value = null)}
                      onChange={handleFileChange}
                      hidden
                    />
                  </label>
                  <label className="picked_file">
                    {!task?.file
                      ? 'Не выбрано'
                      : `Выбран файл:${task.file.name}`}
                  </label>
                </div>

                <input
                  className="send_hw_file"
                  type="submit"
                  value={isLoading ? 'Загрузка...' : 'Отправить'}></input>
              </div>
            </form>
            <div id="pick_data">
              <label>Дата:</label>
              <input
                id="data_picker"
                type="date"
                value={date}
                onChange={handleDateChange}></input>
            </div>
          </div>
          <div
            id="line"
            style={{
              height: '1px',
              width: '100%',
              backgroundColor: 'black',
              margin: '5px auto 15px auto',
            }}></div>

          <div id="all_tabs">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col"></th>
                  {fields.map(field => (
                    <th key={field.id} scope="col">
                      {field.name} ({field.game_tokens})
                    </th>
                  ))}
                  <th scope="col">Дополнительно </th>
                </tr>
              </thead>
              <tbody>
                {selectedGroup?.users.map((user, index) => {
                  let income = incomes.find(item => item.user === user.id)
                  if (income !== undefined)
                    return (
                      <tr key={user.id}>
                        <th scope="row">{index + 1}</th>
                        <td>
                          {user.first_name} {user.last_name}(
                          {user.profile.game_tokens})
                        </td>
                        {fields.map(field => (
                          <td key={`${user.id}_${field.id}`}>
                            <Checkbox
                              checked={income.fields.includes(field.id)}
                              value={field.id}
                              onChange={e => handleIncomeChange(e, user.id)}
                            />
                          </td>
                        ))}

                        <td>
                          <input
                            value={
                              income.additional_game_tokens === 0
                                ? ''
                                : income.additional_game_tokens
                            }
                            type="text"
                            name="add"
                            pattern="^[0-9]*$"
                            onChange={e => handleAdditionalChange(e, user.id)}
                          />
                        </td>
                      </tr>
                    )
                })}
              </tbody>
            </table>
          </div>
          <input
            className="send_hw_file"
            style={{ marginLeft: 'auto' }}
            type="submit"
            value={isLoading ? 'Загрузка...' : 'Подтвердить'}
            onClick={createIncomes}></input>

          <div style={{ margin: '15px 0' }}>
            <label>Домашние задания группы:</label>
          </div>
          <div id="teacher_hw">
            {homeworks.map(hw => (
              <TeacherHW
                key={hw.id}
                hw={hw}
                api={api}
                update={() => updateHomework(hw)}
              />
            ))}
          </div>
          <div id="teacher_hw">
            {doneHomeworks.map(hw => (
              <TeacherHW
                key={hw.id}
                hw={hw}
                api={api}
                update={() => updateHomework(hw)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TeacherAcc
