import React, { useState, useContext, useEffect } from 'react'
import Modal from 'react-modal'
import '../style/GalleryItem.css'
import Heart from '../style/imgs/heart.svg'
import HeartFull from '../style/imgs/heart_full.svg'
import Bubble from '../style/imgs/bubble.svg'
import UserPic from '../style/imgs/user_pic.png'
import WorkPic from '../style/imgs/work_pic.png'
import VertLine from '../style/imgs/vert_line_modal.png'
import GrayCircle from '../style/imgs/gray_circle.png'
import SendButton from '../style/imgs/send_button.svg'
import ExitButton from '../style/imgs/exit_button.png'

import LeftArrow from '../style/imgs/left_arrow.svg'
import RightArrow from '../style/imgs/right_arrow.svg'
import LeftArrowModal from '../style/imgs/left_arrow_modal.svg'
import RightArrowModal from '../style/imgs/right_arrow_modal.svg'
import AltWork from '../style/imgs/no_work_pic.svg'
import AltAvatar from '../style/imgs/no_avatar_pic.svg'

import DeleteButton from '../style/imgs/delete_work.svg'
import AuthContext from '../context/AuthContext'
import useAxios from '../utils/useAxios'

function GalleryItem({ workPar, deleteMe }) {
  let { user } = useContext(AuthContext)
  let api = useAxios()
  let [isLoading, setIsLoading] = useState(false)
  let [isDeleting, setIsDeleting] = useState(false)

  let [work, setWork] = useState(workPar)
  let [comment, setComment] = useState('')

  const [modalIsOpen, setModalIsOpen] = useState(false)
  let [src, setSrc] = useState(workPar?.images[0].image_url)
  let img = 0

  useEffect(() => {
    Modal.setAppElement('body')
  }, [])

  useEffect(() => {
    if (isDeleting && !modalIsOpen) deleteMe()
  }, [isDeleting, modalIsOpen])

  const nextImg = () => {
    img = (img + 1) % work?.images.length
    setSrc(work?.images[img].image_url)
  }

  const prevImg = () => {
    img = img - 1 < 0 ? work?.images.length - 1 : img - 1
    setSrc(work?.images[img].image_url)
  }

  const openModal = () => {
    setModalIsOpen(true)
  }

  const closeModal = () => {
    setModalIsOpen(false)
  }

  useEffect(() => {
    document.body.style.overflow = modalIsOpen ? 'hidden' : 'unset'
  }, [modalIsOpen])

  const canDelete = () => {
    return (
      work?.user.id === user?.user_id ||
      ['admin', 'teacher'].includes(user?.status)
    )
  }

  const canCommentDelete = comment => {
    return (
      comment?.user?.id === user?.user_id ||
      ['admin', 'teacher'].includes(user?.status)
    )
  }

  let deleteWork = async () => {
    if (isLoading) return
    if (!canDelete()) return
    if (!window.confirm('Вы уверены?')) return
    setIsLoading(true)
    try {
      await api.delete(`/api/works/${work.id}/`)
      setIsLoading(false)
      setIsDeleting(true)
      closeModal()
    } catch (error) {
      setIsLoading(false)
    }
  }

  let deleteComment = async comment => {
    if (isLoading) return
    if (!canCommentDelete(comment)) return
    if (!window.confirm('Вы уверены?')) return
    setIsLoading(true)
    try {
      await api.delete(`/api/comments/${comment.id}/`)
      setWork({
        ...work,
        comments: work.comments.filter(item => item.id !== comment.id),
      })
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  let createComment = async () => {
    if (isLoading) return
    if (user === null) return
    if (comment.length === 0) return
    setIsLoading(true)
    var body = { user: user?.user_id, work: work.id, text: comment }
    setComment('')
    try {
      await api.post(`/api/comments/create/`, body)
      let responseComments = await api.get(`/api/works/${work.id}/`)
      setWork({ ...work, comments: responseComments.data.comments })
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  let handleLike = async () => {
    if (isLoading) return
    if (user === null) return
    setIsLoading(true)
    try {
      let response = await api.post(`/api/works/${work.id}/like/`)
      setWork({ ...work, likes: response?.data })
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  function handleCommentChange(event) {
    setComment(event.target.value)
  }

  //содержание модального окна
  const modalContent = (
    <>
      <div id="small_modal">
        <label id="modal_warning">Недоступно на данном устройсте.</label>
      </div>

      <button
        id="close_modal_button"
        className="hide_button"
        onClick={closeModal}>
        <img src={ExitButton} alt="Закрыть"></img>
      </button>

      <div id="modal_content">
        {/**левая часть модального окна */}
        <div id="left">
          <div id="about_item_modal">
            <img
              src={
                work?.user?.profile?.avatar?.image
                  ? work?.user?.profile?.avatar?.image
                  : AltAvatar
              }
              style={{
                width: '50px',
                height: '50px',
                objectFit: 'cover',
                borderRadius: '30px',
              }}
              alt="Аватарка"
            />

            <div id="about_item_stats">
              <div className="about_item" style={{ marginLeft: '15px' }}>
                <label id="user_name_modal">{work?.user?.first_name}</label>
                <label id="user_surname_modal">{work?.user?.last_name}</label>
              </div>

              <div className="about_item" style={{ marginLeft: '10px' }}>
                {work?.is_public && (
                  <>
                    <button className="hide_button" onClick={handleLike}>
                      <img
                        id="heart"
                        src={
                          work?.likes.includes(user?.user_id)
                            ? HeartFull
                            : Heart
                        }
                        alt="Лайк"
                      />
                    </button>
                    <div id="heart_count">{work?.likes.length}</div>
                    <img
                      id="message"
                      src={Bubble}
                      style={{ marginLeft: '10px' }}
                      alt="Коммент"></img>
                    <div id="message_count">{work?.comments.length}</div>
                  </>
                )}
                {canDelete() && (
                  <button
                    className="hide_button"
                    id="delete_work_modal"
                    onClick={deleteWork}>
                    <img
                      id="delete_work_button"
                      src={DeleteButton}
                      alt="Удалить"
                    />
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className="about_item_pic">
            {work?.images.length > 1 && (
              <button
                className="hide_button"
                id="left_arrow_modal"
                onClick={prevImg}>
                <img
                  src={LeftArrowModal}
                  id="left_arrow_modal_pic"
                  style={{ height: '50px', marginRight: '5px' }}
                  alt="Влево"></img>
              </button>
            )}
            <img
              id="image_work_modal"
              src={src}
              onError={() => setSrc(AltWork)}
              style={{
                objectFit: 'contain',
                width: '80%',
                height: '80%',
              }}
              alt="Работа"></img>
            {work?.images.length > 1 && (
              <button
                className="hide_button"
                id="right_arrow_modal"
                onClick={nextImg}>
                <img
                  src={RightArrowModal}
                  id="right_arrow_modal_pic"
                  style={{ height: '50px', marginLeft: '5px' }}
                  alt="Вправо"></img>
              </button>
            )}
          </div>
        </div>

        <img
          src={VertLine}
          id='vert_line'
          style={{ height: '85vh', margin: '0px 10px' }}
          alt="Разделитель"></img>

        {/** правая часть модального окна */}
        <div id="right">
          <label id="name_work_modal">{work?.name}</label>
          <div
            id="line"
            style={{
              height: '1px',
              width: '100%',
              backgroundColor: 'black',
              margin: ' 10px auto 10px auto',
            }}></div>
          <label id="about_work_modal">{work?.description}</label>

          <div
            id="line"
            style={{
              height: '1px',
              width: '100%',
              backgroundColor: 'black',
              margin: ' 10px auto 10px auto',
            }}></div>

          <div id="comments_wrap">
            {work?.comments.map((comment, index) => (
              <div id="about_item_modal" key={index}>
                <img
                  src={
                    comment.user?.profile?.avatar?.image
                      ? comment.user?.profile?.avatar?.image
                      : AltAvatar
                  }
                  style={{ width: '30px', 
                  height: '30px',
                  borderRadius: '20px',
                  objectFit: 'cover'}}
                  alt="Аватарка"
                />

                <div id="about_item_stats" style={{ alignItems: 'flex-start' }}>
                  <div className="about_item" style={{ marginLeft: '15px' }}>
                    <label id="user_name_modal">
                      {comment.user?.first_name}
                    </label>
                    <label
                      id="user_surname_modal"
                      style={{ marginLeft: '5px' }}>
                      {comment.user?.last_name}
                    </label>
                    {canCommentDelete(comment) && (
                      <button
                        className="hide_button"
                        id="delete_work_modal"
                        onClick={() => deleteComment(comment)}>
                        <img
                          id="delete_work_button"
                          src={DeleteButton}
                          alt="Удалить"
                        />
                      </button>
                    )}
                  </div>

                  <div id="comment" style={{ marginLeft: '15px' }}>
                    {comment.text}
                  </div>
                </div>
              </div>
            ))}
          </div>
          {user !== null &&
            (work?.is_public || user?.user_id === work?.user.id) && (
              <div id="wrap_write_comment">
                <div
                  id="line"
                  style={{
                    height: '1px',
                    width: '100%',
                    backgroundColor: 'white',
                    margin: ' 10px auto 10px auto',
                  }}></div>

                <div id="write_comment">
                  {/* <img id='avatar_comment' src={GrayCircle}></img> */}
                  <input
                    id="input_comment"
                    placeholder="Написать комментарий"
                    value={comment}
                    onChange={handleCommentChange}></input>
                  <button className="hide_button" onClick={createComment}>
                    <img
                      id="send_button"
                      src={SendButton}
                      alt="Отправить"></img>
                  </button>
                </div>
              </div>
            )}
        </div>
      </div>
    </>
  )

  return (
    <div id="back_item">
      <div className="about_item" style={{ marginLeft: '15px' }}>
        <img
          id="pic"
          src={
            work?.user?.profile?.avatar?.image
              ? work?.user?.profile?.avatar?.image
              : AltAvatar
          }
          alt="Аватар"
        />

        <label id="user_work_name">{work?.name},</label>
        <label id="user_name">{work?.user?.first_name}</label>
        <label id="user_surname">{work?.user?.last_name}</label>
      </div>

      <div className="about_item" style={{ justifyContent: 'center' }}>
        {work?.images.length > 1 && (
          <button id="left_arrow" onClick={prevImg}>
            <img src={LeftArrow} alt="Влево" />
          </button>
        )}
        <button className="hide_button" onClick={openModal}>
          <img
            id="pic_work"
            src={src}
            onError={() => setSrc(AltWork)}
            alt="Работа"></img>
        </button>
        {work?.images.length > 1 && (
          <button id="right_arrow" onClick={nextImg}>
            <img src={RightArrow} alt="Вправо" />
          </button>
        )}
      </div>

      <div className="about_item" style={{ marginLeft: '15px' }}>
        {work?.is_public && (
          <>
            <button className="hide_button" onClick={handleLike}>
              <img
                id="heart"
                src={work?.likes.includes(user?.user_id) ? HeartFull : Heart}
                alt="Лайк"
              />
            </button>
            <div id="heart_count">{work?.likes.length}</div>
            <button
              style={{
                height: '30px',
                width: '40px',
                marginLeft: '10px',
                padding: '0',
                backgroundColor: 'transparent',
                border: 'none',
              }}
              onClick={openModal}>
              <img id="message" src={Bubble} alt="Коммент"></img>
            </button>
            <div id="message_count">{work?.comments.length}</div>
          </>
        )}
        {canDelete() && (
          <button className="hide_button" id="delete_work" onClick={deleteWork}>
            <img id="delete_work_button" src={DeleteButton} alt="Удалить" />
          </button>
        )}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            zIndex: 100,
            backgroundColor: 'rgba(217, 217, 217, .8)',
            overFlow: 'hidden',
          },
        }}>
        {modalContent}
      </Modal>
    </div>
  )
}

export default GalleryItem
