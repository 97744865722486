import React, { useState, useEffect } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import Chip from '@mui/material/Chip'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import '../style/Admin.css'
import ExitButton from '../style/imgs/exit_button.svg'
import CharPic from '../style/imgs/CharPic.png'

import Modal from 'react-modal'
import PickAvatar from '../components/PickAvatar'
import useAxios from '../utils/useAxios'

function Admin() {
  let api = useAxios()
  let [isLoading, setIsLoading] = useState(false)

  let [users, setUsers] = useState([])
  let [groups, setGroups] = useState([])
  let [categories, setCategories] = useState([])
  let [avatars, setAvatars] = useState([])
  let [fields, setFields] = useState([])

  useEffect(() => {
    document.title = 'Админ-панель'
    getData()
  }, [])

  let getData = async () => {
    setIsLoading(true)
    try {
      let response = await api.get(`/api/admin/`)
      let data = response?.data
      setUsers(data?.users)
      setGroups(data?.groups)
      setCategories(data?.categories)
      setFields(data?.fields)
      let responseAvatars = await api.get(`/api/avatars/`)
      let dataAvatars = responseAvatars.data
      setAvatars(
        dataAvatars?.map(item => {
          return { ...item, category: item.category?.id }
        }),
      )
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  }

  function AdminAccount() {
    let clearUserData = {
      username: '',
      password: '',
      first_name: '',
      last_name: '',
      study_groups: [],
      profile: {
        sex: '',
        birth_date: '',
        status: '',
      },
    }

    let [newUserData, setNewUserData] = useState({ ...clearUserData })
    let [updateUserData, setUpdateUserData] = useState({
      id: '',
      ...clearUserData,
    })

    let createUser = async e => {
      e.preventDefault()
      if (isLoading) return
      if (users.map(user => user.username).includes(newUserData.username)) {
        alert('Такой аккаунт уже существует')
        return
      }
      setIsLoading(true)
      try {
        let response = await api.post(`/api/users/create/`, {
          ...newUserData,
          study_groups: newUserData.study_groups.map(item => item.id),
        })
        setNewUserData({ ...clearUserData })
        setUsers([...users, response.data])
        setIsLoading(false)
        alert('Аккаунт успешно создан')
      } catch (error) {
        setIsLoading(false)
      }
    }

    let updateUser = async e => {
      e.preventDefault()
      if (isLoading) return
      if (
        updateUserData.profile.game_tokens != 0 &&
        !Number(updateUserData.profile.game_tokens)
      ) {
        alert('Колонка "Геймтокены" заполнена некорректно')
        return
      }
      setIsLoading(true)
      try {
        let response = await api.patch(`/api/users/${updateUserData.id}/`, {
          ...updateUserData,
          study_groups: updateUserData.study_groups.map(item => item.id),
        })
        setUpdateUserData({ id: '', ...clearUserData })
        setUsers([
          ...users.filter(item => item.id !== response.data.id),
          response.data,
        ])
        setIsLoading(false)
        alert('Аккаунт успешно отредактирован')
      } catch (error) {
        setIsLoading(false)
      }
    }

    let deleteUser = async e => {
      e.preventDefault()
      if (isLoading) return
      setIsLoading(true)
      let id = updateUserData.id
      try {
        await api.delete(`/api/users/${id}/`)
        setUpdateUserData({ id: '', ...clearUserData })
        setUsers([...users.filter(item => item.id !== id)])
        setIsLoading(false)
        alert('Аккаунт успешно удален')
      } catch (error) {
        setIsLoading(false)
      }
    }

    function handleLastNameChange(event) {
      setNewUserData({ ...newUserData, last_name: event.target.value })
    }

    function handleFirstNameChange(event) {
      setNewUserData({ ...newUserData, first_name: event.target.value })
    }

    function handleSexChange(event) {
      setNewUserData({
        ...newUserData,
        profile: {
          ...newUserData.profile,
          sex: event.target.checked ? event.target.value : '',
        },
      })
    }

    function handleBirthDateChange(event) {
      setNewUserData({
        ...newUserData,
        profile: {
          ...newUserData.profile,
          birth_date: event.target.value,
        },
      })
    }

    function handleStatusChange(event, value) {
      setNewUserData({
        ...newUserData,
        study_groups: [],
        profile: {
          ...newUserData.profile,
          status: value === null ? '' : value.status,
        },
      })
    }

    function handleGroupsChange(event) {
      setNewUserData({ ...newUserData, study_groups: event.target.value })
    }

    function handleUsernameChange(event) {
      setNewUserData({ ...newUserData, username: event.target.value })
    }

    function handlePasswordChange(event) {
      setNewUserData({ ...newUserData, password: event.target.value })
    }

    function handleUserChange(event, value) {
      if (value !== null) {
        let myGroups = []
        let groupIds = groups.map(item => item.id)
        value.study_groups.forEach(item => {
          if (groupIds.includes(item))
            myGroups.push(groups.find(g => g.id === item))
        })
        setUpdateUserData({
          ...value,
          password: '',
          study_groups: [...myGroups],
        })
      } else setUpdateUserData({ id: '', ...clearUserData })
    }

    function handleUpdateLastNameChange(event) {
      setUpdateUserData({ ...updateUserData, last_name: event.target.value })
    }

    function handleUpdateFirstNameChange(event) {
      setUpdateUserData({ ...updateUserData, first_name: event.target.value })
    }

    function handleUpdateSexChange(event) {
      setUpdateUserData({
        ...updateUserData,
        profile: {
          ...updateUserData.profile,
          sex: event.target.value,
        },
      })
    }

    function handleUpdateBirthDateChange(event) {
      setUpdateUserData({
        ...updateUserData,
        profile: {
          ...updateUserData.profile,
          birth_date: event.target.value,
        },
      })
    }

    function handleUpdateGameTokensChange(event) {
      setUpdateUserData({
        ...updateUserData,
        profile: {
          ...updateUserData.profile,
          game_tokens: event.target.value,
        },
      })
    }

    function handleUpdateStatusChange(event, value) {
      setUpdateUserData({
        ...updateUserData,
        profile: {
          ...updateUserData.profile,
          status: value === null ? '' : value.status,
        },
      })
    }

    function handleUpdateGroupsChange(event) {
      setUpdateUserData({ ...updateUserData, study_groups: event.target.value })
    }

    function handleUpdateUsernameChange(event) {
      setUpdateUserData({ ...updateUserData, username: event.target.value })
    }

    function handleUpdatePasswordChange(event) {
      setUpdateUserData({ ...updateUserData, password: event.target.value })
    }

    function handle(e) {
      if (e.nativeEvent.submitter.value === 'update') updateUser(e)
      if (e.nativeEvent.submitter.value === 'delete') deleteUser(e)
    }

    let [incomesHistory, setIncomesHistory] = useState([[], []])
    let incomesOwner = -1

    async function showIncomesHistory(event) {
      event.preventDefault()
      openModal()
      if (incomesOwner !== updateUserData?.id) {
        let response = await api.get(`/api/users/${updateUserData?.id}/`)
        setIncomesHistory([response.data.incomes, response.data.bills])
        incomesOwner = updateUserData?.id
      }
    }

    useEffect(() => {
      Modal.setAppElement('body')
    }, [])

    const [modalIsOpen, setModalIsOpen] = useState(false)

    const openModal = () => {
      setModalIsOpen(true)
    }

    const closeModal = () => {
      setModalIsOpen(false)
    }

    let billTitles = [
      { title: 'От администратора', description: 'byAdmin' },
      { title: 'Покупка аватарки', description: 'buyAvatar' },
      { title: 'Другое', description: 'others' },
    ]

    const ModalContent = (
      <div
        className="history"
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
        }}>
        <div className="left">
          <label>От преподавателя</label>
          {incomesHistory[0]
            .map(item => {
              let group = groups.find(g => g.id === item.group)
              let sum = 0
              return (
                <div key={item.id} className="history_item">
                  <label>{`${
                    group !== undefined ? `${group.name} - ` : ``
                  }${new Date(item.date).toLocaleDateString('ru-RU')}`}</label>
                  {item.fields.map(field => {
                    let f = fields.find(i => i.id === field)
                    sum += f.game_tokens
                    if (f !== undefined) {
                      return (
                        <div>
                          {f.name}: {f.game_tokens}
                        </div>
                      )
                    }
                  })}
                  {item.additional_game_tokens != 0 && (
                    <div>Дополнительно: {item.additional_game_tokens}</div>
                  )}
                  <div>Итого: {sum + item.additional_game_tokens}</div>
                </div>
              )
            })
            .reverse()}
        </div>
        <div className="right">
          <label>Другие</label>

          {incomesHistory[1]
            .map(item => {
              let title = billTitles.find(
                t => t.description === item.description,
              )
              if (title === undefined) {
                title = { title: 'Неизвестно' }
              }
              return (
                <div key={item.id} className="history_item">
                  <label>
                    {new Date(item.created_at).toLocaleDateString('ru-RU')}
                  </label>
                  <div>
                    {title.title}: {item.game_tokens}
                  </div>
                </div>
              )
            })
            .reverse()}
        </div>
      </div>
    )

    let statuses = [
      { title: 'Администратор', status: 'admin' },
      { title: 'Преподаватель', status: 'teacher' },
      { title: 'Ученик', status: 'student' },
    ]

    return (
      <>
        <form className="all_input" onSubmit={createUser}>
          <label className="create_word">Создание аккаунта</label>

          <Autocomplete
            disablePortal
            isOptionEqualToValue={(option, value) =>
              option.status === value.status
            }
            options={statuses}
            getOptionLabel={status => status.title}
            className="autocomplete_style"
            onChange={handleStatusChange}
            renderInput={params => (
              <TextField {...params} label="Тип пользователя" />
            )}
          />
          <div id="gender">
            <input
              type="checkbox"
              className="checkbox_gender"
              value="M"
              checked={newUserData.profile.sex === 'M'}
              onChange={handleSexChange}></input>
            <label className="name_gender">Мужской</label>
            <input
              type="checkbox"
              className="checkbox_gender"
              value="F"
              checked={newUserData.profile.sex === 'F'}
              onChange={handleSexChange}></input>
            <label className="name_gender">Женский</label>
          </div>
          <input
            placeholder="Фамилия"
            className="input_admin"
            type="text"
            value={newUserData.last_name}
            onChange={handleLastNameChange}></input>
          <input
            placeholder="Имя"
            className="input_admin"
            value={newUserData.first_name}
            onChange={handleFirstNameChange}></input>
          <input
            placeholder="Дата рождения"
            className="input_admin"
            type="date"
            value={newUserData.profile.birth_date}
            onChange={handleBirthDateChange}></input>
          <input
            placeholder="Логин"
            className="input_admin"
            value={newUserData.username}
            onChange={handleUsernameChange}></input>
          <input
            placeholder="Пароль"
            className="input_admin"
            value={newUserData.password}
            onChange={handlePasswordChange}></input>

          {/** Form control here is helping to use an InputLabel + labelId, which actually create a placeholder*/}
          {newUserData.profile.status === 'student' && (
            <FormControl>
              <InputLabel id="placeholder_select">Выберите группу</InputLabel>
              <Select
                className="select_style"
                labelId="placeholder_select"
                label="Выберите группу"
                multiple
                value={newUserData?.study_groups}
                onChange={handleGroupsChange}
                renderValue={selected => (
                  <Stack gap={1} direction="row" flexWrap="wrap">
                    {selected.map(value => (
                      <Chip key={value.id} label={value.name} />
                    ))}
                  </Stack>
                )}
                MenuProps={MenuProps}>
                {groups.map(group => (
                  <MenuItem key={group.id} value={group}>
                    <Checkbox
                      checked={newUserData?.study_groups
                        .map(g => g.id)
                        .includes(group.id)}
                    />
                    <ListItemText primary={group.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <button type="submit" className="admin_submit_button">
            {isLoading ? 'Загрузка...' : 'Создать'}
          </button>
        </form>

        <form className="all_input" onSubmit={handle}>
          <label className="create_word">Редактирование аккаунта</label>
          <Autocomplete
            disablePortal
            options={users}
            getOptionLabel={user => user.last_name + ' ' + user.first_name}
            className="autocomplete_style"
            onChange={handleUserChange}
            renderInput={params => (
              <TextField {...params} label="Поиск аккаунта" />
            )}
          />
          {!!updateUserData.id && (
            <>
              <div>
                Тип:{' '}
                {statuses.find(
                  item => item.status === updateUserData?.profile.status,
                ) !== undefined
                  ? statuses.find(
                      item => item.status === updateUserData?.profile.status,
                    ).title
                  : ``}
              </div>
              <Autocomplete
                disablePortal
                isOptionEqualToValue={(option, value) =>
                  option.status === value.status
                }
                options={statuses}
                getOptionLabel={status => status.title}
                className="autocomplete_style"
                onChange={handleUpdateStatusChange}
                renderInput={params => (
                  <TextField {...params} label="Cменить тип пользователя" />
                )}
              />
              <div id="gender">
                <input
                  type="checkbox"
                  className="checkbox_gender"
                  value="M"
                  checked={updateUserData.profile.sex === 'M'}
                  onChange={handleUpdateSexChange}></input>
                <label className="name_gender">Мужской</label>
                <input
                  type="checkbox"
                  className="checkbox_gender"
                  value="F"
                  checked={updateUserData.profile.sex === 'F'}
                  onChange={handleUpdateSexChange}></input>
                <label className="name_gender">Женский</label>
              </div>
              <input
                placeholder="Фамилия"
                className="input_admin"
                value={updateUserData?.last_name}
                onChange={handleUpdateLastNameChange}></input>
              <input
                placeholder="Имя"
                className="input_admin"
                value={updateUserData?.first_name}
                onChange={handleUpdateFirstNameChange}></input>
              <input
                placeholder="Дата рождения"
                className="input_admin"
                type="date"
                value={updateUserData?.profile?.birth_date}
                onChange={handleUpdateBirthDateChange}></input>
              {updateUserData?.profile?.status === 'student' && (
                <>
                  <input
                    placeholder="Геймтокены"
                    className="input_admin"
                    value={updateUserData?.profile?.game_tokens}
                    onChange={handleUpdateGameTokensChange}></input>
                  <button onClick={showIncomesHistory}>
                    История транзакций
                  </button>
                </>
              )}
              <input
                placeholder="Логин"
                className="input_admin"
                value={updateUserData?.username}
                onChange={handleUpdateUsernameChange}></input>
              <input
                placeholder="Пароль"
                className="input_admin"
                value={updateUserData?.password}
                onChange={handleUpdatePasswordChange}></input>

              {/** Form control here is helping to use a InputLabel + labelId, which actually create a placeholder*/}
              {updateUserData.profile.status === 'student' && (
                <FormControl>
                  <InputLabel id="placeholder_select">
                    Выберите группу
                  </InputLabel>
                  <Select
                    className="select_style"
                    labelId="placeholder_select"
                    label="Выберите группу"
                    multiple
                    value={updateUserData?.study_groups}
                    onChange={handleUpdateGroupsChange}
                    input={<OutlinedInput label="Name" />}
                    renderValue={selected => (
                      <Stack gap={1} direction="row" flexWrap="wrap">
                        {selected.map(value => (
                          <Chip key={value.id} label={value.name} />
                        ))}
                      </Stack>
                    )}
                    MenuProps={MenuProps}>
                    {groups.map(group => (
                      <MenuItem key={group.id} value={group}>
                        <Checkbox
                          checked={updateUserData?.study_groups
                            .map(g => g.id)
                            .includes(group.id)}
                        />
                        <ListItemText primary={group.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              <div id="admin_bottom_buttons">
                <button
                  type="submit"
                  className="admin_submit_button"
                  value="update">
                  {isLoading ? 'Загрузка...' : 'Подтвердить'}
                </button>
                <button
                  type="submit"
                  className="admin_delete_button"
                  value="delete">
                  {isLoading ? 'Загрузка...' : 'Удалить'}
                </button>
              </div>
            </>
          )}
        </form>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={{
            overlay: {
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              zIndex: '100',
            },
            content: {
              background: 'rgba(195, 228, 255, 1)',
              borderRadius: '20px',
              zIndex: '150',
            },
          }}>
          {ModalContent}
        </Modal>
      </>
    )
  }

  function AdminGroup() {
    let clearGroup = {
      teacher: null,
      name: '',
      users: [],
    }

    let [newGroup, setNewGroup] = useState({ ...clearGroup })
    let [updateGroupData, setUpdateGroupData] = useState({
      id: '',
      ...clearGroup,
    })

    let createGroup = async e => {
      e.preventDefault()
      if (isLoading) return
      setIsLoading(true)
      try {
        let response = await api.post(`/api/groups/create/`, {
          ...newGroup,
          teacher: newGroup.teacher?.id,
          users: newGroup.users.map(item => item.id),
        })
        setNewGroup({ ...clearGroup })
        setGroups([...groups, response.data])
        setIsLoading(false)
        alert('Группа успешно создана')
      } catch (error) {
        setIsLoading(false)
      }
    }

    let prepareForUpdate = () => {
      let data = { ...updateGroupData }
      if (data.name === '') delete data.name
      return data
    }

    let updateGroup = async e => {
      e.preventDefault()
      if (isLoading) return
      setIsLoading(true)
      let data = prepareForUpdate()
      try {
        let response = await api.patch(`/api/groups/${data.id}/`, {
          ...data,
          teacher: data.teacher?.id,
          users: data.users.map(item => item.id),
        })
        setUpdateGroupData({ id: '', ...clearGroup })
        setGroups([
          ...groups.filter(item => item.id !== response.data.id),
          response.data,
        ])
        setIsLoading(false)
        alert('Группа успешно отредактирована')
      } catch (error) {
        setIsLoading(false)
      }
    }

    let deleteGroup = async e => {
      e.preventDefault()
      if (isLoading) return
      setIsLoading(true)
      let id = updateGroupData.id
      try {
        await api.delete(`/api/groups/${id}/`)
        setUpdateGroupData({ id: '', ...clearGroup })
        setGroups([...groups.filter(item => item.id !== id)])
        setIsLoading(false)
        alert('Группа успешно удалена')
      } catch (error) {
        setIsLoading(false)
      }
    }

    function handle(e) {
      if (e.nativeEvent.submitter.value === 'update') updateGroup(e)
      if (e.nativeEvent.submitter.value === 'delete') deleteGroup(e)
    }

    function handleTeacherChange(event, value) {
      setNewGroup({ ...newGroup, teacher: value })
    }

    function handleNameChange(event) {
      setNewGroup({ ...newGroup, name: event.target.value })
    }

    function handleUsersChange(event, value) {
      setNewGroup({ ...newGroup, users: value })
    }

    function handleGroupChange(event, value) {
      if (value !== null) {
        let myTeacher = users.find(item => item.id === value.teacher)
        let myUsers = []
        let usersIds = users.map(item => item.id)
        value.users.forEach(item => {
          if (usersIds.includes(item))
            myUsers.push(users.find(u => u.id === item))
        })
        setUpdateGroupData({
          ...value,
          teacher: myTeacher ? myTeacher : null,
          users: [...myUsers],
        })
      } else setUpdateGroupData({ id: '', ...clearGroup })
    }

    function handleUpdateTeacherChange(event, value) {
      setUpdateGroupData({ ...updateGroupData, teacher: value })
    }

    function handleUpdateNameChange(event) {
      setUpdateGroupData({ ...updateGroupData, name: event.target.value })
    }

    function handleUpdateUsersChange(event, value) {
      setUpdateGroupData({ ...updateGroupData, users: value })
    }

    return (
      <>
        <form className="all_input" onSubmit={createGroup}>
          <label className="create_word">Создание группы</label>

          <input
            placeholder="Название группы"
            className="input_admin"
            value={newGroup.name}
            onChange={handleNameChange}></input>

          <Autocomplete
            disablePortal
            options={users.filter(u => u.profile.status === 'teacher')}
            getOptionLabel={user => user.last_name + ' ' + user.first_name}
            className="autocomplete_style"
            onChange={handleTeacherChange}
            value={newGroup.teacher}
            renderInput={params => (
              <TextField {...params} label="Выбор преподавателя" />
            )}
          />

          <Autocomplete
            multiple
            className="autocomplete_style"
            value={newGroup.users}
            onChange={handleUsersChange}
            options={users.filter(u => u.profile.status === 'student')}
            getOptionLabel={option =>
              option.last_name + ' ' + option.first_name
            }
            renderInput={params => (
              <TextField {...params} label="Добавить ученика" />
            )}
          />
          <button type="submit" className="admin_submit_button">
            {isLoading ? 'Загрузка...' : 'Создать'}
          </button>
        </form>

        <form className="all_input" onSubmit={handle}>
          <label className="create_word">Редактирование группы</label>

          <Autocomplete
            disablePortal
            options={groups}
            getOptionLabel={option => option.name}
            className="autocomplete_style"
            onChange={handleGroupChange}
            renderInput={params => (
              <TextField {...params} label="Найти группу" />
            )}
          />

          {!!updateGroupData.id && (
            <>
              <input
                placeholder="Название группы"
                className="input_admin"
                value={updateGroupData.name}
                onChange={handleUpdateNameChange}></input>

              <Autocomplete
                disablePortal
                options={users.filter(u => u.profile.status === 'teacher')}
                getOptionLabel={user => user.last_name + ' ' + user.first_name}
                className="autocomplete_style"
                value={updateGroupData.teacher}
                onChange={handleUpdateTeacherChange}
                renderInput={params => (
                  <TextField {...params} label="Выбор преподавателя" />
                )}
              />

              <Autocomplete
                multiple
                className="autocomplete_style"
                value={updateGroupData.users}
                onChange={handleUpdateUsersChange}
                options={users.filter(u => u.profile.status === 'student')}
                getOptionLabel={option =>
                  option.last_name + ' ' + option.first_name
                }
                renderInput={params => (
                  <TextField {...params} label="Добавить ученика" />
                )}
              />
              <div id="admin_bottom_buttons">
                <button
                  type="submit"
                  className="admin_submit_button"
                  value="update">
                  {isLoading ? 'Загрузка...' : 'Подтвердить'}
                </button>
                <button
                  type="submit"
                  className="admin_delete_button"
                  value="delete">
                  {isLoading ? 'Загрузка...' : 'Удалить'}
                </button>
              </div>
            </>
          )}
        </form>
      </>
    )
  }

  function AdminAvatar() {
    const clearAvatar = {
      image: null,
      price: 0,
      category: '',
    }
    const clearCategory = {
      id: -1,
      name: '',
    }
    let [avatar, setAvatar] = useState(clearAvatar)
    let [avatarOnDelete, setAvatarOnDelete] = useState(null)
    let [category, setCategory] = useState(clearCategory)

    useEffect(() => {
      Modal.setAppElement('body')
    }, [])

    let createCategory = async () => {
      if (isLoading) return
      setIsLoading(true)
      try {
        let response = await api.post(`/api/avatar_categories/create/`, {
          name: category.name,
        })
        setCategories([...categories, response.data])
        alert('Категория успешно создана')
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
      }
    }

    let deleteCategory = async () => {
      if (isLoading) return
      if (category.id === -1) {
        alert('Вы не выбрали категорию')
        return
      }
      setIsLoading(true)
      try {
        let id = category.id
        await api.delete(`/api/avatar_categories/${id}/`)
        setCategories(categories.filter(item => item.id !== id))
        setCategory({ ...category, id: -1 })
        alert('Категория успешно удалена')
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
      }
    }

    let createAvatar = async () => {
      if (isLoading) return
      if (!avatar.image) {
        alert('Не выбрана картинка')
        return
      }
      if (avatar.price < 0) {
        alert('Цена не может быть отрицательна')
        return
      }
      setIsLoading(true)
      const formData = new FormData()
      formData.append(`image`, avatar.image, avatar.image?.name)
      formData.append(`price`, avatar.price)
      formData.append(`category`, avatar.category)
      try {
        let response = await api.post(`/api/avatars/create/`, formData)
        setAvatars([
          ...avatars,
          { ...response.data, category: response.data.category.id },
        ])
        setIsLoading(false)
        alert('Аватарка успешно создана')
      } catch (error) {
        setIsLoading(false)
      }
    }

    let deleteAvatar = async () => {
      if (isLoading) return
      if (!avatarOnDelete) {
        alert('Вы не выбрали аватарку')
        return
      }
      setIsLoading(true)
      try {
        let id = avatarOnDelete.id
        await api.delete(`/api/avatars/${id}/`)
        setAvatars(avatars.filter(item => item.id !== id))
        setAvatarOnDelete(null)
        setIsLoading(false)
        alert('Аватарка успешно удалена')
      } catch (error) {
        setIsLoading(false)
      }
    }

    function handleCategoryNameChange(event) {
      setCategory({ ...category, name: event.target.value })
    }

    function handleCategoryChangeOnDelete(event, value) {
      if (!value) setCategory({ ...category, id: -1 })
      else setCategory({ ...category, id: value.id })
    }

    function handleImageChange(event) {
      setAvatar({ ...avatar, image: event.target.files[0] })
    }

    function handlePriceChange(event) {
      setAvatar({ ...avatar, price: event.target.value })
    }

    function handleCategoryChange(event, value) {
      setAvatar({ ...avatar, category: value ? value.id : '' })
    }

    //функции модального окна
    const [modalIsOpen, setModalIsOpen] = useState(false)

    const openModal = () => {
      setModalIsOpen(true)
    }

    const closeModal = () => {
      setModalIsOpen(false)
    }

    const [categoryInList, setCategoryInList] = useState('')

    function handleCategoryInListChange(value) {
      setCategoryInList(value)
    }

    const handleAvatar = item => {
      closeModal()
      setAvatarOnDelete(item)
    }

    useEffect(() => {
      document.body.style.overflow = modalIsOpen ? 'hidden' : 'unset'
    }, [modalIsOpen])

    const ModalContent = (
      <>
        <button
          id="close_avatar_pick"
          className="hide_button"
          onClick={closeModal}>
          <img src={ExitButton} style={{ width: '25px' }} alt="Закрыть"></img>
        </button>

        <div id="pick_avatar_modal">
          <div id="avatar_cat">
            <button
              className={categoryInList === '' ? 'category_picked' : 'category'}
              onClick={() => handleCategoryInListChange('')}>
              Все аватарки
            </button>
            {categories.map(item => (
              <button
                key={item.id}
                className={
                  categoryInList === item.name ? 'category_picked' : 'category'
                }
                onClick={() => handleCategoryInListChange(item.name)}>
                {item.name}
              </button>
            ))}
          </div>

          <div id="pick_avatar">
            {avatars
              .filter(a => {
                let c = categories.find(item => item.id === a.category)
                return (
                  categoryInList === '' ||
                  (c !== undefined && c.name === categoryInList)
                )
              })
              .map(item => (
                <PickAvatar
                  key={item.id}
                  avatar={item}
                  isHaving={true}
                  isIt={item.id === avatar.id}
                  handle={_ => handleAvatar(item)}
                />
              ))}
          </div>
        </div>
      </>
    )

    return (
      <div id="admin_avatar_wrap">
        <div className="admin_avatar">
          <label className="create_word">Добавить категорию</label>
          <input
            className="input_admin"
            type="text"
            placeholder="Введите название"
            value={category.name}
            onChange={handleCategoryNameChange}></input>
          <button className="admin_submit_button" onClick={createCategory}>
            Сохранить
          </button>
          <label className="create_word" style={{ marginBottom: '15px' }}>
            Удалить категорию
          </label>
          <Autocomplete
            className="autocomplete_style"
            onChange={handleCategoryChangeOnDelete}
            options={categories}
            getOptionLabel={option => option.name}
            renderInput={params => (
              <TextField {...params} label="Выбрать категорию" />
            )}
          />
          <button
            className="admin_delete_button"
            style={{ margin: '20px 0 20px 0' }}
            onClick={deleteCategory}>
            Удалить
          </button>
        </div>

        <div className="admin_avatar">
          <label className="create_word" style={{ marginBottom: '15px' }}>
            Добавить аватарку
          </label>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}></input>
          <label id="cost_word">Цена аватарки</label>
          <input
            className="input_admin_avatar"
            type="number"
            placeholder="Введите цену"
            value={avatar.price}
            onChange={handlePriceChange}></input>
          <label id="cost_word">Выберите категорию</label>
          <Autocomplete
            className="autocomplete_style"
            onChange={handleCategoryChange}
            options={categories}
            getOptionLabel={option => option.name}
            renderInput={params => (
              <TextField {...params} label="Выбрать категорию" />
            )}
          />
          <button className="admin_submit_button" onClick={createAvatar}>
            Сохранить
          </button>
          <label className="create_word" style={{ marginBottom: '15px' }}>
            Удалить аватарку
          </label>
          <div id="profile" style={{ alignItems: 'start' }}>
            <button id="btn_picture" onClick={openModal}>
              <img
                id="picture"
                src={avatarOnDelete === null ? CharPic : avatarOnDelete.image}
                alt="Аватарка"></img>
            </button>
          </div>
          <button
            className="admin_delete_button"
            style={{ margin: '20px 0 20px 0' }}
            onClick={deleteAvatar}>
            Удалить
          </button>
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={{
            overlay: {
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              zIndex: '100',
            },
            content: {
              background: 'rgba(195, 228, 255, 1)',
              borderRadius: '20px',
              zIndex: '150',
            },
          }}>
          {ModalContent}
        </Modal>
      </div>
    )
  }

  function AdminTask() {
    let clearTask = {
      topic: '',
      environment: '',
      text: '',
      file: null,
    }
    let [task, setTask] = useState({ ...clearTask })
    let [date, setDate] = useState(new Date().toLocaleDateString('en-CA'))
    let [group, setGroup] = useState({})

    useEffect(() => {
      console.log(group)
      if (!!group.id && !!date) {
        getTask()
      }
    }, [group.id, date])

    let getTask = async () => {
      let response = await api.post('/api/tasks/find/', {
        date: date,
        group: group.id,
      })
      if (response?.data !== 'Not found') setTask(response.data)
      else setTask({ ...clearTask })
    }

    let createTask = async () => {
      if (isLoading) return
      if (!date) {
        alert('Выберите дату')
        return
      }
      if (!group.id) {
        alert('Выберите группу')
        return
      }
      setIsLoading(true)
      const formData = new FormData()
      formData.append(`user`, group.teacher)
      formData.append(`group`, group.id)
      formData.append(`topic`, task.topic)
      formData.append(`environment`, task.environment)
      formData.append(`text`, task.text)
      if (!!date) formData.append(`date`, date)
      if (!!task.file) formData.append(`file_url`, task.file, task.file.name)
      try {
        await api.post(`/api/tasks/create/`, formData)
        setIsLoading(false)
        alert('Задание выдано успешно')
      } catch (error) {
        setIsLoading(false)
      }
    }

    let updateTask = async () => {
      if (isLoading) return
      setIsLoading(true)
      const formData = new FormData()
      formData.append(`topic`, task.topic)
      formData.append(`environment`, task.environment)
      formData.append(`text`, task.text)
      if (task.file_url === '') formData.append(`file_url`, '')
      if (!!task.file) formData.append(`file_url`, task.file, task.file.name)
      try {
        await api.patch(`/api/tasks/${task.id}/`, formData)
        setIsLoading(false)
        alert('Задание изменено успешно')
      } catch (error) {
        setIsLoading(false)
      }
    }

    let sendTask = e => {
      e.preventDefault()
      if (!!task?.id) updateTask()
      else createTask()
    }

    function handleGroupChange(event, value) {
      value ? setGroup({ ...value }) : setGroup({})
    }

    function handleTopicChange(event) {
      setTask({ ...task, topic: event.target.value })
    }

    function handleEnvironmentChange(event) {
      setTask({ ...task, environment: event.target.value })
    }

    function handleTextChange(event) {
      setTask({ ...task, text: event.target.value })
    }

    function handleFileChange(event) {
      setTask({ ...task, file: event.target.files[0] })
    }

    function handleDateChange(event) {
      setDate(event.target.value)
    }

    let deleteFile = e => {
      setTask({ ...task, file_url: '' })
    }

    return (
      <form
        id="teacher_create_hw"
        onSubmit={sendTask}
        style={{ margin: '10px' }}>
        <input
          placeholder="Дата"
          className="input_admin"
          type="date"
          value={date}
          onChange={handleDateChange}></input>
        <Autocomplete
          disablePortal
          options={groups}
          getOptionLabel={option => option.name}
          className="autocomplete_style"
          onChange={handleGroupChange}
          renderInput={params => <TextField {...params} label="Найти группу" />}
        />
        <input
          className="about_hw"
          type="text"
          placeholder="Тема занятия"
          value={task.topic}
          onChange={handleTopicChange}></input>
        <input
          className="about_hw"
          type="text"
          placeholder="Среда занятия"
          value={task.environment}
          onChange={handleEnvironmentChange}></input>
        <textarea
          rows="10"
          className="about_hw"
          id="hw_text"
          placeholder="Текст домашнего задания"
          value={task.text}
          onChange={handleTextChange}></textarea>
        <div id="hw_button">
          {!!task?.file_url && (
            <>
              <label
                className="picked_file"
                style={{
                  margin: '10px',
                }}>{`Текущий файл: ${task?.file_url.split('/').pop()}`}</label>
              <div>
                {' '}
                <button id="delete_hw_file" onClick={() => deleteFile()}>
                  Удалить файл
                </button>
              </div>
            </>
          )}
          <div id="choose_file">
            <label id="choose_hw_file">
              {!!task?.file_url ? 'Перевыбрать' : 'Прикрепить файл'}
              <input
                type="file"
                onClick={e => (e.target.value = null)}
                onChange={handleFileChange}
                hidden
              />
            </label>
            <label className="picked_file">
              {!task?.file ? 'Не выбрано' : `Выбран файл:${task.file.name}`}
            </label>
          </div>

          <input
            className="send_hw_file"
            type="submit"
            value={isLoading ? 'Загрузка...' : 'Отправить'}></input>
        </div>
      </form>
    )
  }

  const [showAdminAccount, setShowAdminAccount] = useState('account')

  const toggleComponent = e => {
    setShowAdminAccount(e.target.name)
  }

  return (
    <div style={{ overflowX: 'hidden' }}>
      <label id="admin">АДМИНИСТРАТОР</label>
      <div
        id="line"
        style={{
          height: '1px',
          width: '98%',
          backgroundColor: 'black',
          margin: ' 10px auto 0px auto',
        }}></div>

      <div id="admin_head_buttons">
        <button
          name="account"
          className={
            showAdminAccount === 'account'
              ? 'admin_head_button_active'
              : 'admin_head_button'
          }
          onClick={toggleComponent}>
          Аккаунт
        </button>
        <button
          name="group"
          className={
            showAdminAccount === 'group'
              ? 'admin_head_button_active'
              : 'admin_head_button'
          }
          onClick={toggleComponent}>
          Группы
        </button>
        <button
          name="avatar"
          className={
            showAdminAccount === 'avatar'
              ? 'admin_head_button_active'
              : 'admin_head_button'
          }
          onClick={toggleComponent}>
          Аватарки
        </button>
        <button
          name="task"
          className={
            showAdminAccount === 'task'
              ? 'admin_head_button_active'
              : 'admin_head_button'
          }
          onClick={toggleComponent}>
          Задание
        </button>
      </div>
      {showAdminAccount === 'account' && <AdminAccount />}
      {showAdminAccount === 'group' && <AdminGroup />}
      {showAdminAccount === 'avatar' && <AdminAvatar />}
      {showAdminAccount === 'task' && <AdminTask />}
    </div>
  )
}

export default Admin
